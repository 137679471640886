import { apiHeadersBuilder } from '../api-helpers/index.js'
import { RAILS_ROUTES } from '../constants/index.js'

// Some of this is duplicated in the store and the dashboard
// As of 2019-08-02, the dashboard and this home page/unit page
// warnings are separate.
// The plan is to phase this out and move to the more dynamic dashboard
// for the main user interaction

export default {
  name: 'UnitApplicationPlacementWarnings',
  props: ['csrfToken'],
  data () {
    return {
      rr: RAILS_ROUTES,
      unitId: 'all',
      placementWarnings: []
    }
  },
  created () {
    // get the last segment of the url for the id
    const url = new URL(window.location.href)
    const urlSections = url.pathname.split("/")
    // get the unit, api call may need this
    if (urlSections[urlSections.length - 2] === "units") {
      this.unitId = urlSections[urlSections.length - 1]
    }

    this.axios.all([this.getPlacementWarnings()])
      .then((response) => {
        this.placementWarnings = response[0]['data']
      })
  },
  computed: {
    warningsPresent: function () {
      if (this.placementWarnings.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    getPlacementWarnings: function () {
      let apiEndpoint = ''

      if (this.unitId === 'all') {
        apiEndpoint = '/unit_application_placement_warnings.json'
      } else {
        apiEndpoint = '/unit_application_placement_warnings.json?unit_id=' + this.unitId
      }

      return this.axios.get(
        apiEndpoint,
        apiHeadersBuilder(this.csrfToken)
      )
    }
  }
}
