import CpsAutocompleteObj from '../autocomplete/autocomplete-obj.vue'
import { RAILS_ROUTES } from '../constants/index.js'
import { apiHeadersBuilder } from '../api-helpers/index.js'
import { format, parseISO, addMonths, differenceInMonths } from 'date-fns'
import {
  filter as Rfilter,
  find as Rfind,
  propEq as RpropEq
} from 'ramda'

const dateString = 'EEEE, MMMM d yyyy'

export default {
  name: 'UnitApplicationPlacementForm',
  components: {
    CpsAutocompleteObj
  },
  props: ['csrfToken', 'unitApplicationPlacementJson'],
  data () {
    return {
      rr: RAILS_ROUTES,
      errorMessages: [],
      unitApplicationPlacement: {
        replaces: {
          value: 0,
          label: ''
        },
        unitApplication: {
          value: 0,
          label: ''
        },
        unit: {
          value: 0,
          label: ''
        }
      },
      unitApplicationPlacementReplaced: {},
      unitApplicationReplaced: {},
      unitPositionTermLength: {},
      unitApplicationPlacementsList: [],
      unitApplicationsList: [],
      uapsWithSameApplicationId: [],
      uapsSameDateRange: [],
      unitsList: []
    }
  },
  created () {
    // call the functions to load the data for the selects
    this.axios.all([this.getUnitApplications(), this.getUnitApplicationPlacements(), this.getUnits()])
      .then((response) => {
        const uaList = response[0]['data']
        const uapList = response[1]['data']
        const uList = response[2]['data']
        this.unitApplicationsList = uaList
        this.unitApplicationPlacementsList = uapList
        this.unitsList = uList

        let bUnitApplicationPlacement = JSON.parse(this.unitApplicationPlacementJson)

        this.unitApplicationPlacement = bUnitApplicationPlacement

        // find the unit for the select
        if (this.unitApplicationPlacement['unitId']) {
          const u = Rfind(RpropEq('value', this.unitApplicationPlacement['unitId']), this.unitsList)

          if (u) {
            this.$refs.unitInput.setResultParent(u)
          }
        }

        // find the application for the select
        if (this.unitApplicationPlacement['unitApplicationId']) {
          const ua = Rfind(RpropEq('value', this.unitApplicationPlacement['unitApplicationId']), this.unitApplicationsList)
          if (ua) {
            this.$refs.unitApplicationInput.setResultParent(ua)
          }
        }

        // find the application placement for the replaces select
        if (this.unitApplicationPlacement['replacesId']) {
          const p = Rfind(RpropEq('value', this.unitApplicationPlacement['replacesId']), this.unitApplicationPlacementsList)
          if (p) {
            this.$refs.replacesInput.setResultParent(p)
          }

          // get the info on the person that is being replaced
          this.getReplaces()
        }
      })
  },
  watch: {
    unitApplicationId: function (newId, oldId) {
      this.getUnitApplicationPlacementsByUnitApplicationId()
    },
    unitId: function (newId, oldId) {
      this.getUnitApplicationPlacementsForDateRange()
    },
    startDate: function (newDate, oldDate) {
      this.getUnitApplicationPlacementsForDateRange()
    }
  },
  computed: {
    startDate: function () {
      return this.unitApplicationPlacement['startDate']
    },
    unitId: function () {
      return this.unitApplicationPlacement['unitId']
    },
    unitApplicationId: function () {
      return this.unitApplicationPlacement['unitApplicationId']
    },
    formattedStartDate: function () {
      return format(parseISO(this.unitApplicationPlacement['startDate']), dateString)
    },
    formattedEndDate: function () {
      return format(parseISO(this.unitApplicationPlacement['endDate']), dateString)
    },
    termLength: function () {
      if (this.unitApplicationPlacement['startDate'] && this.unitApplicationPlacement['endDate']) {
        return differenceInMonths(parseISO(this.unitApplicationPlacement['endDate']), parseISO(this.unitApplicationPlacement['startDate']))
      } else {
        return null
      }
    }
  },
  methods: {
    getUnitApplicationPlacementsForDateRange: function () {
      // early return
      if (!this.unitApplicationPlacement['unitId']) {
        return {}
      } else if (!this.unitApplicationPlacement['startDate']) {
        return {}
      }

      const rangeStartDate = format(addMonths(parseISO(this.unitApplicationPlacement['startDate']), -1), 'yyyy-MM-dd')
      const rangeEndDate = format(addMonths(parseISO(this.unitApplicationPlacement['startDate']), 1), 'yyyy-MM-dd')

      this.axios.get(
        '/unit_application_placements.json?unit_id=' + this.unitApplicationPlacement['unitId'] + '&range_start_date=' + rangeStartDate + '&range_end_date=' + rangeEndDate,
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          const uapsResponse = response['data']
          // filter out this application
          const uapsFiltered = Rfilter((item) => {
            return item['id'] !== this.unitApplicationPlacement.id
          }, uapsResponse)

          this.uapsSameDateRange = uapsFiltered

        })
    },
    getUnitApplicationPlacementsByUnitApplicationId: function () {
      // early return
      if (!this.unitApplicationPlacement['unitApplicationId']) {
        return {}
      }

      this.axios.get(
        '/unit_application_placements.json?unit_application_id=' + this.unitApplicationPlacement['unitApplicationId'],
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          const uapsResponse = response['data']
          // filter out the ones that match this placements id
          const uapsFiltered = Rfilter((item) => {
            return item['id'] !== this.unitApplicationPlacement.id
          }, uapsResponse)

          // ok, first check if there is zero, one, or multiple records returned
          if (uapsFiltered.length === 0) {
            // there aren't any, it doesn't matter what they do
            return {}
          } else {
            this.uapsWithSameApplicationId = uapsFiltered
          }
        })
    },
    updateUnit: function (evt) {
      this.unitApplicationPlacement.unit = evt
      this.unitApplicationPlacement.unitId = evt.value
    },
    updateUnitApplication: function (evt) {
      this.unitApplicationPlacement.unitApplication = evt
      this.unitApplicationPlacement.unitApplicationId = evt.value
    },
    updateReplaces: function (evt) {
      this.unitApplicationPlacement.replaces = evt
      this.unitApplicationPlacement.replacesId = evt.value
      this.getReplaces() // update the necessary values
    },
    getUnitApplication: function () {
      // early return
      if (!this.unitApplicationPlacement['unitApplicationId']) {
        return {}
      }

      this.axios.get(
        '/unit_applications/' + this.unitApplicationPlacement['unitApplicationId'] + `.json`,
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getReplaces: function () {

      // early return
      if (!this.unitApplicationPlacement['replacesId']) {
        return {}
      }

      this.axios.get(
        '/unit_application_placements/' + this.unitApplicationPlacement['replacesId'] + '.json',
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          this.unitApplicationPlacementReplaced = response['data']
          this.unitApplicationPlacementReplaced['formattedEndDate'] =
            format(parseISO(this.unitApplicationPlacementReplaced['endDate']), dateString)

          // going to set some of the values for the placement, if they aren't already set
          // start date, should be same as end date of the replaced
          if (!this.unitApplicationPlacement['startDate']) {
            this.unitApplicationPlacement['startDate'] = this.unitApplicationPlacementReplaced['endDate']
          }

          // set the value for the unit
          if (!this.unitApplicationPlacement['unitId']) {
            this.unitApplicationPlacement['unitId'] = this.unitApplicationPlacementReplaced['unitId']

            const u = Rfind(RpropEq('value', this.unitApplicationPlacementReplaced['unitId']), this.unitsList)

            if (u) {
              this.$refs.unitInput.setResultParent(u)
            }
          }

        })
        .then((result) => {
          // get the unit application for this person
          return this.axios.get(
            '/unit_applications/' + this.unitApplicationPlacementReplaced['unitApplicationId'] + '.json',
            apiHeadersBuilder(this.csrfToken)
          )
        })
        .then((response) => {
          this.unitApplicationReplaced = response['data']
        })
        .then((result) => {
          // call for the unit application position term length
          return this.axios.get(
            '/unit_position_term_lengths/search.json?unit_id=' + this.unitApplicationPlacementReplaced['unitId'] + '&position=' + this.unitApplicationReplaced['position'],
            apiHeadersBuilder(this.csrfToken)
          )
        })
        .then((response) => {
          this.unitPositionTermLength = response['data']
          // got the needed data, fill in some more values
          if (!this.unitApplicationPlacement['endDate']) {
            if (this.unitApplicationPlacement['startDate']) {
              // add the term length months to the start date
              this.unitApplicationPlacement['endDate'] =
                format(addMonths(parseISO(this.unitApplicationPlacement['startDate']), this.unitPositionTermLength['termLength']), 'yyyy-MM-dd')
            }
          }
        })
    },
    apiParamsBuilder: function () {
      return {
        'utf8': '✓',
        'authenticity_token': this.csrfToken,
        'id': this.unitApplicationPlacement['id'],
        'commit': 'Save changes',
        'unit_application_placement': {
          'replaces_id': this.unitApplicationPlacement['replacesId'],
          'unit_application_id': this.unitApplicationPlacement['unitApplicationId'],
          'start_date': this.unitApplicationPlacement['startDate'],
          'end_date': this.unitApplicationPlacement['endDate'],
          'unit_id': this.unitApplicationPlacement['unitId'],
          'status': this.unitApplicationPlacement['status']
        }
      }
    },
    onSubmit: function (event) {
      event.preventDefault()

      let apiResponse

      if (this.unitApplicationPlacement['id'] === null) {
        // it's not a new application placement
        // post the data
        apiResponse = this.axios.post(
          '/unit_application_placements',
          this.apiParamsBuilder(),
          apiHeadersBuilder(this.csrfToken)
        )
      } else {
        // the form is in edit mode
        apiResponse = this.axios.put(
          '/unit_application_placements/' + this.unitApplicationPlacement['id'],
          this.apiParamsBuilder(),
          apiHeadersBuilder(this.csrfToken)
        )
      }

      apiResponse
        .then((response) => {
          window.location.pathname = `/unit_application_placements/${response['data']['id']}`
        })
        .catch((error) => {
          this.errorMessages = error['response']['data']['errors']
          window.scrollTo(0,0)
        })

    },
    unitApplicationSelectChange: function (val) {
      if (val != null) {
        this.unitApplicationPlacement['unitApplicationId'] = val['value']
      }
    },
    unitSelectChange: function (val) {
      if (val != null) {
        this.unitApplicationPlacement['unitId'] = val['value']
      }
    },
    getUnitApplications: function () {
      return this.axios.get(
        '/unit_applications_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getUnitApplicationPlacements: function () {
      return this.axios.get(
        '/unit_application_placements_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getUnits: function () {
      return this.axios.get(
        '/units_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    }
  }
}
