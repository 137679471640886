// {"userId":1,"isAdmin":true,"loggedIn":true,"currentPreparatoryClassSeasonDescription":"2019-2020 Preparatory Class Season","appRoleResponsibility":"coordinator"}

const state = {
  userId: 0,
  isAdmin: false,
  loggedIn: false,
  currentPreparatoryClassSeasonDescription: "",
  appRoleResponsibility: "recruiter"
}

const mutations = {
  LOAD_USER_ID (state, payload) {
    state.userId = payload
  },
  LOAD_USER_IS_ADMIN (state, payload) {
    state.isAdmin = payload
  },
  LOAD_USER_LOGGED_IN (state, payload) {
    state.loggedIn = payload
  },
  LOAD_USER_CURRENT_PREPARATORY_CLASS_SEASON_DESCRIPTION (state, payload) {
    state.currentPreparatoryClassSeasonDescription = payload
  },
  LOAD_USER_APP_ROLE_RESPONSIBILITY (state, payload) {
    state.appRoleResponsibility = payload
  },
}

const actions = {
  storeUserData({ commit }, userData) {
    commit("LOAD_USER_ID", userData["userId"])
    commit("LOAD_USER_IS_ADMIN", userData["isAdmin"])
    commit("LOAD_USER_LOGGED_IN", userData["loggedIn"])
    commit("LOAD_USER_CURRENT_PREPARATORY_CLASS_SEASON_DESCRIPTION", userData["currentPreparatoryClassSeasonDescription"])
    commit("LOAD_USER_APP_ROLE_RESPONSIBILITY", userData["appRoleResponsibility"])
  }
}

const getters = {
  userId: state => {
    return state.userId;
  },
  userIsAdmin: state => {
    return state.isAdmin
  }
}

const userDataModule = {
  state,
  mutations,
  actions,
  getters
}

export default userDataModule
