import api from '../../../api/index.js'

const state = {
  recruiterWarnings: []
}

const mutations = {
  LOAD_RECRUITER_WARNINGS (state, payload) {
    state.recruiterWarnings = payload
  },
}

const actions = {
  needRecruiterWarnings({ commit }, csrfToken) {
    return api.getRecruiterWarnings(csrfToken)
      .then(response => {
        const payload = response['data']
        commit("LOAD_RECRUITER_WARNINGS", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {
  recruiterWarnings: state => {
    return state.recruiterWarnings
  },
  recruiterWarningsCount: state => {
    return state.recruiterWarnings.length
  }
}

const recruiterWarnings = {
  state,
  mutations,
  actions,
  getters
}

export default recruiterWarnings
