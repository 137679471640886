import { reduce as Rreduce } from 'ramda'

const arrivingUnitVolunteersReportsPathBuilder = (reportOptions = {}) => {
  const base_url = '/reports/arriving_unit_volunteers'
  // function needs to handle four things in the call
  // format, reportDateRangeStart, reportDateRangeEnd, and unitIds
  if (reportOptions.hasOwnProperty('format')) {
    if (reportOptions.hasOwnProperty('reportDateRangeStart')) {
      if (reportOptions.hasOwnProperty('unitIds')) {
        let unitIdsQuery = Rreduce((acc, value) => {
          return `${acc}report_units[unit_ids][]=${value}&`
        }, '', reportOptions['unitIds'])
        return `${base_url}.${reportOptions['format']}?report_date_range_end=${reportOptions['reportDateRangeEnd']}&report_date_range_start=${reportOptions['reportDateRangeStart']}&${unitIdsQuery}`
      } else {
        return `${base_url}.${reportOptions['format']}?report_date_range_end=${reportOptions['reportDateRangeEnd']}&report_date_range_start=${reportOptions['reportDateRangeStart']}`
      }
    } else {
      return `${base_url}.${reportOptions['format']}`
    }
  } else { // no options passed in
    return base_url
  }
};

const currentPersonnelReportsPathBuilder = (reportOptions = {}) => {
  const base_url = '/reports/current_personnel'
  // function needs to handle three things in the call
  // format, reportDate, and unitIds
  if (reportOptions.hasOwnProperty('format')) {
    if (reportOptions.hasOwnProperty('reportDate')) {
      if (reportOptions.hasOwnProperty('unitIds')) {
        let unitIdsQuery = Rreduce((acc, value) => {
          return `${acc}report_units[unit_ids][]=${value}&`
        }, '', reportOptions['unitIds'])
        return `${base_url}.${reportOptions['format']}?report_date=${reportOptions['reportDate']}&${unitIdsQuery}`
      } else {
        return `${base_url}.${reportOptions['format']}?report_date=${reportOptions['reportDate']}`
      }
    } else {
      return `${base_url}.${reportOptions['format']}`
    }
  } else { // no options passed in
    return base_url
  }
};

const unitApplicationsReportsPathBuilder = (reportOptions) => {
  if (reportOptions.hasOwnProperty('position')) {
    return `/reports/unit_applications.${reportOptions['format']}?position=${reportOptions['position']}`
  } else if (reportOptions.hasOwnProperty('unitId')) {
    return `/reports/unit_applications.${reportOptions['format']}?unit_id=${reportOptions['unitId']}`
  } else {
    return `/reports/unit_applications.${reportOptions['format']}`
  }
};

const personnelLeavingReportsPathBuilder = (reportOptions) => {
  if (reportOptions.hasOwnProperty('position')) {

    const optionPositions = reportOptions['position']

    if (Array.isArray(reportOptions['position'])) {
      let positions = Rreduce((acc, value) => {
        return `${acc}position[]=${value}&`
      }, '', reportOptions['position'])

      // could take the ampersand off of the end, but it doesn't seem to affect anything

      return `/reports/personnel_leaving.${reportOptions['format']}?${positions}`
    } else {
      return `/reports/personnel_leaving.${reportOptions['format']}?position=${reportOptions['position']}`
    }
  } else if (reportOptions.hasOwnProperty('unitId')) {
    return `/reports/personnel_leaving.${reportOptions['format']}?unit_id=${reportOptions['unitId']}`
  } else {
    return `/reports/personnel_leaving.${reportOptions['format']}`
  }
};

export const RAILS_ROUTES = {
  'arriving_unit_volunteers_reports_path': arrivingUnitVolunteersReportsPathBuilder,
  'congregations_path': '/congregations',
  'countries_path': '/countries',
  'current_personnel_reports_path': currentPersonnelReportsPathBuilder,
  'current_unit_application_placements_path': '/unit_application_placements/current',
  'recruiter_dashboard': '/recruiter/recruiter_dashboard/',
  'place_unit_applications': '/recruiter/place_unit_applications/#/potentiallyAvailable',
  'current_unit_applications_path': '/unit_applications/current',
  'current_units_path': '/units/current',
  'drivers_license_insurance_reports_path': '/reports/drivers_license_insurance',
  'edit_user_path': (userId) => `/users/${userId}/edit`,
  'edit_unit_path': (unitId) => `/units/${unitId}/edit`,
  'help_path': '/help',
  'coordinator_help_path': '/class/coordinator_help',
  'about_path': '/about',
  'contact_path': '/contact',
  'login_path': '/login',
  'logout_path': '/logout',
  'new_unit_application_path': '/unit_applications/new',
  'people_path': '/people',
  'person': (personId) => `/people/${personId}`,
  'personnel_leaving_reports_path': personnelLeavingReportsPathBuilder,
  'recent_activity_reports_path': '/reports/recent_activity',
  'root_path': '/',
  'states_path': '/states',
  'applications_by_congregation_path': '/class/reports/applications_by_congregation',
  'text_documents_path': '/text_documents',
  'edit_text_document_path': (tdId) => `/text_documents/${tdId}/edit`,
  'preparatory_class_seasons_path': '/class/preparatory_class_seasons',
  'new_preparatory_class_application_path': '/class/preparatory_class_applications/new',
  'new_preparatory_class_season_path': '/class/preparatory_class_seasons/new',
  'edit_preparatory_class_season_path': (ssId) => `/class/preparatory_class_seasons/${ssId}/edit`,
  'edit_preparatory_class_application_path': (pcaId) => `/class/preparatory_class_applications/${pcaId}/edit`,
  'preparatory_class_application_path': (pcaId) => `/class/preparatory_class_applications/${pcaId}`,
  'preparatory_class_applications_path': '/class/preparatory_class_applications',
  'preparatory_class_application_preferences_path': '/class/preparatory_class_application_preferences',
  'preparatory_classes_path': '/class/preparatory_classes',
  'instructors_path': '/class/instructors',
  'new_instructor_path': '/class/instructors/new',
  'edit_instructor_path': (instructorId) => `/class/instructors/${instructorId}/edit`,
  'preparatory_class_path': (pcId) => `/class/preparatory_classes/${pcId}`,
  'preparatory_class_people_report_path': (pcId) => `/class/preparatory_class_people_report?preparatory_class_id=${pcId}`,
  'preparatory_class_certificates_path': (pcId) => `/class/preparatory_class_certificates?preparatory_class_id=${pcId}`,
  'preparatory_class_welcome_letter_path': (pcaId) => `/class/preparatory_class_welcome_letter?preparatory_class_application_id=${pcaId}`,
  'new_preparatory_class_path': '/class/preparatory_classes/new',
  'edit_preparatory_class_path': (pClassId) => `/class/preparatory_classes/${pClassId}/edit`,
  'statistics_reports_path': '/reports/statistics',
  'unit_application_placements_path': '/unit_application_placements',
  'unit_application_placement_path': (placementId) => `/unit_application_placements/${placementId}`,
  'unit_applications_path': '/unit_applications',
  'unit_application_path': (applicationId) => `/unit_applications/${applicationId}`,
  'unit_applications_reports_path': unitApplicationsReportsPathBuilder,
  'units_path': '/units',
  'unit': (unitId) => `/units/${unitId}`,
  'user': (userId) => `/users/${userId}`,
  'users_path': '/users',
}

