import { RAILS_ROUTES } from '../constants/index.js'

export default {
  name: 'MenuCurrentPersonnelReport',
  props: ['reportDate', 'unitIds', 'unitsList'],
  data () {
    return {
      rr: RAILS_ROUTES,
      selectedUnitIds: this.unitIds
    }
  }
}
