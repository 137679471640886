import { apiHeadersBuilder } from '../api-helpers/index.js'
import { globalBus } from '../packs/app_vue_compiler.js'

export default {
  name: 'AddToCancelationList',
  props: [
    'csrfToken',
    'unitId',
    'unitApplicationId'
  ],
  data () {
    return {}
  },
  methods: {
    addToList: function () {
      const params = {
        'unit_id': this.unitId,
        'unit_application_id': this.unitApplicationId
      }
      this.axios.post(
        '/unit_application_cancelation_spots',
        params,
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          globalBus.$emit('cancelationListChanged', response['data'])
        })

    }
  }
}
