import api from '../../../api/index.js'

const state = {
  pinnedUnitApplications: [],
  pinnedUnitApplication: {},
  pinnedUnitApplicationPlacements: [],
  pinnedUnitApplicationPlacement: {}
}

const mutations = {
  LOAD_PINNED_UNIT_APPLICATIONS (state, payload) {
    state.pinnedUnitApplications = payload
  },
  LOAD_PINNED_UNIT_APPLICATION (state, payload) {
    state.pinnedUnitApplication = payload
  },
  LOAD_PINNED_UNIT_APPLICATION_PLACEMENTS (state, payload) {
    state.pinnedUnitApplicationPlacements = payload
  },
  LOAD_PINNED_UNIT_APPLICATION_PLACEMENT (state, payload) {
    state.pinnedUnitApplicationPlacement = payload
  }
}

const actions = {
  pinUnitApplication({ commit }, params) {
    return api.pinUnitApplication(params)
      .then(response => {
        const payload = response['data']
        commit("LOAD_PINNED_UNIT_APPLICATION", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  unpinUnitApplication({ commit }, params) {
    return api.unpinUnitApplication(params)
      .then(response => {
        const payload = {}
        commit("LOAD_PINNED_UNIT_APPLICATION", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        const payload = {}
        commit("LOAD_PINNED_UNIT_APPLICATION", payload)
        return Promise.reject(error)
      })
  },
  getPinnedUnitApplication({ commit }, params) {
    return api.getPinnedUnitApplication(params)
      .then(response => {
        const payload = response['data']
        commit("LOAD_PINNED_UNIT_APPLICATION", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        // the item may not exist
        // we expect to get an error here if it is not found
        // just let the component know everything is fine
        return Promise.resolve(error)
      })
  },
  needPinnedUnitApplications({ commit }, csrfToken) {
    return api.getPinnedUnitApplications(csrfToken)
      .then(response => {
        const payload = response['data']
        commit("LOAD_PINNED_UNIT_APPLICATIONS", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  // placements start here
  pinUnitApplicationPlacement({ commit }, params) {
    return api.pinUnitApplicationPlacement(params)
      .then(response => {
        const payload = response['data']
        commit("LOAD_PINNED_UNIT_APPLICATION_PLACEMENT", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  unpinUnitApplicationPlacement({ commit }, params) {
    return api.unpinUnitApplicationPlacement(params)
      .then(response => {
        const payload = {}
        commit("LOAD_PINNED_UNIT_APPLICATION_PLACEMENT", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        const payload = {}
        commit("LOAD_PINNED_UNIT_APPLICATION_PLACEMENT", payload)
        return Promise.reject(error)
      })
  },
  getPinnedUnitApplicationPlacement({ commit }, params) {
    return api.getPinnedUnitApplicationPlacement(params)
      .then(response => {
        const payload = response['data']
        commit("LOAD_PINNED_UNIT_APPLICATION_PLACEMENT", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        // the item may not exist
        // we expect to get an error here if it is not found
        // just let the component know everything is fine
        return Promise.resolve(error)
      })
  },
  needPinnedUnitApplicationPlacements({ commit }, csrfToken) {
    return api.getPinnedUnitApplicationPlacements(csrfToken)
      .then(response => {
        const payload = response['data']
        commit("LOAD_PINNED_UNIT_APPLICATION_PLACEMENTS", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {
  pinnedUnitApplications: state => {
    return state.pinnedUnitApplications
  },
  pinnedUnitApplication: state => {
    return state.pinnedUnitApplication
  },
  pinnedUnitApplicationPlacements: state => {
    return state.pinnedUnitApplicationPlacements
  },
  pinnedUnitApplicationPlacement: state => {
    return state.pinnedUnitApplicationPlacement
  }
}

const pinnedItems = {
  state,
  mutations,
  actions,
  getters
}

export default pinnedItems
