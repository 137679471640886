import { reduce as Rreduce } from 'ramda'

export default {
  name: 'MenuUapShow',
  data () {
    return {
      selected: []
    }
  },
  methods: {
    sortByDate: function () {
      const tableAll = document.getElementById('unit-applications-table-all')

      const sourceTables = [
        document.getElementById('unit-applications-table-same-unit'),
        document.getElementById('unit-applications-table-similar-unit'),
        document.getElementById('unit-applications-table-similar-position')
      ]

      // check and see if the rows have been copied
      if (tableAll.tBodies[0].rows.length == 0) {

        // clone the table bodies, we want them to still be in their source tables
        // for when the user toggles them
        const clonedTblBodies = sourceTables.map((tbl) => {
          return tbl.tBodies[0].cloneNode(true)
        })

        // convert them to a sortable array
        const rows = Rreduce((acc, tblBody) => {
          const tblRows = [].slice.call(tblBody.rows)
          return [...acc, ...tblRows]
        }, [], clonedTblBodies)

        // sort the rows
        const cellPosition = 8
        rows.sort((a, b) => {
          const aValue = a.cells[cellPosition].textContent
          const bValue = b.cells[cellPosition].textContent

          if (aValue > bValue) {
            return 1;
          } else if (aValue < bValue) {
            return -1;
          } else {
            return 0;
          }
        })

        // append them back to their table
        let fragment = document.createDocumentFragment();
        rows.forEach((row) => {
          fragment.appendChild(row);
        });

        tableAll.tBodies[0].appendChild(fragment);

      }

      // toggle the tables visibility
      for (const tbl of sourceTables) {
        tbl.closest('div').classList.toggle('d-none') // bootstrap specific
      }
      tableAll.closest('div').classList.toggle('d-none') // bootstrap specific

    },
    filterByStatus: function () {
      const rows = document.querySelectorAll('tr.data')

      for (let i = 0; i < rows.length; ++i) {
        const rowValue = rows[i].cells[3].textContent.trim()
        if (this.selected.includes(rowValue)) {
          // show the row
          rows[i].classList.remove('d-none') // bootstrap specific
        } else {
          // hide the row
          rows[i].classList.add('d-none') // bootstrap specific
        }
      }

    },
    clearFilterByStatus: function () {
      const rows = document.querySelectorAll('tr.data')
      for (let i = 0; i < rows.length; ++i) {
        rows[i].classList.remove('d-none') // bootstrap specific
      }
    }
  }
}
