import {
  tbodySortRows,
  tableFilterRows,
  tableFilterRowsByRowValues,
  tableFilterRowsRemoveFilter
} from '../utilities/index.js'
import InPageSearch from './../in-page-search/in-page-search.vue'

export default {
  name: 'MenuUap',
  components: {
    InPageSearch
  },
  data () {
    return {
      startValue: null,
      endValue: null
    }
  },
  methods: {
    filterByDateRange: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        startValue: this.startValue,
        endValue: this.endValue,
        startValuePosition: 4,
        endValuePosition: 5
      }

      tableFilterRowsByRowValues(options, tables)
    },
    clearDateRangeFilter: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')

      tableFilterRowsRemoveFilter({}, tables)
    },
    showBoys: function () {
      const tablesToFilter = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        classesToAdd: ['show-boys'],
        classesToRemove: ['show-girls', 'show-other']
      }

      tableFilterRows(options, tablesToFilter)
    },
    showGirls: function () {
      const tablesToFilter = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        classesToAdd: ['show-girls'],
        classesToRemove: ['show-boys', 'show-other']
      }

      tableFilterRows(options, tablesToFilter)
    },
    showAll: function () {
      const tablesToFilter = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        classesToAdd: [],
        classesToRemove: ['show-boys', 'show-girls', 'show-other']
      }

      tableFilterRows(options, tablesToFilter)
    },
    showOther: function () {
      const tablesToFilter = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        classesToAdd: ['show-other'],
        classesToRemove: ['show-boys', 'show-girls']
      }
      tableFilterRows(options, tablesToFilter)
    },
    sortByName: function () {
      const tbody = document.querySelectorAll('tbody.js-sortable')
      tbodySortRows({'position': 0}, tbody)
    },
    sortByStartDate: function () {
      const tbody = document.querySelectorAll('tbody.js-sortable')
      tbodySortRows({'position': 4}, tbody)
    }
  }
}
