export default {
  name: 'UaNotes',
  props: [
    'csrfToken',
    'isAdmin',
    'unitApplicationId',
  ],
  data () {
    return {
      noteText: null,
      notes: [],
    }
  },
  created () {
    this.axios.get(
      `/unit_application_notes.json?unit_application_id=${this.unitApplicationId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      }
    )
      .then((response) => {
        this.notes = response['data']
      })
  },
  methods: {
    onSubmit: function (event) {
      event.preventDefault()

      // post the data
      this.axios.post(
        '/unit_application_notes',
        {
        unit_application_note: {
          unit_application_id: this.unitApplicationId,
          content: this.noteText,
        }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRF-Token': this.csrfToken
          }
        }
      )
        .then((response) => {
          this.notes = response['data']
          this.noteText = null
          this.$refs.noteTextArea.$el.focus()
        })
    },
    deleteNote: function (noteId) {
      this.axios.delete(`/unit_application_notes/${noteId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      })
        .then((response) => {
          this.notes = response['data']
        })
    }
  }
}
