import { apiHeadersBuilder } from '../api-helpers/index.js'
import UnitApplicationAddToCancelationList from '../unit-application-cancelation-list/add-to-cancelation-list.vue'
import UnitApplicationRemoveFromCancelationList from '../unit-application-cancelation-list/remove-from-cancelation-list.vue'
import { map as Rmap, find as Rfind } from 'ramda'
import { globalBus } from '../packs/app_vue_compiler.js'

export default {
  name: 'UnitApplicationPreferences',
  components: {
    UnitApplicationAddToCancelationList,
    UnitApplicationRemoveFromCancelationList,
  },
  props: [
    'csrfToken',
    'unitApplicationId'
  ],
  data () {
    return {
      unitApplicationPreferences: [],
      unitApplicationCancelationSpots: []
    }
  },
  created () {
    this.refreshData()
    globalBus.$on('cancelationListChanged', (data) => {
      this.refreshData()
    })
  },
  methods: {
    getUnitApplicationPreferences: function () {
      return this.axios.get(
        '/unit_application_preferences.json?unit_application_id=' + this.unitApplicationId,
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getUnitApplicationCancelationSpots: function () {
      return this.axios.get(
        '/unit_application_cancelation_spots.json?unit_application_id=' + this.unitApplicationId,
        apiHeadersBuilder(this.csrfToken)
      )
    },
    refreshData: function () {
      // get the data
      this.axios.all([
        this.getUnitApplicationPreferences(),
        this.getUnitApplicationCancelationSpots()
      ])
        .then((response) => {
          const cancelationSpots = response[1]['data']

          const preferences = Rmap((p) => {

            const cancelationSpot = Rfind((c) => {
              return c.unitId === p.unitId
            }, cancelationSpots)

            let cancelationData = {}

            if (cancelationSpot) {
              cancelationData = {
                unitApplicationCancelationSpotId: cancelationSpot.id,
                onCancelationList: true
              }
            } else {
              cancelationData = {
                unitApplicationCancelationSpotId: 0,
                onCancelationList: false
              }
            }

            return {
              ...p,
              ...cancelationData
            }
          }, response[0]['data'])

          this.unitApplicationPreferences = preferences
          this.unitApplicationCancelationSpots = cancelationSpots
        })
    }
  }
}
