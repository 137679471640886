import { apiHeadersBuilder } from '../api-helpers/index.js'
import { RAILS_ROUTES } from '../constants/index.js'
import { filter as Rfilter } from 'ramda'
import { store as statisticsStore } from '../preparatory-class-statistics-store/index.js'
import { friendlyDate } from '../form-helpers/index.js'

export default {
  name: 'PreparatoryClassesIndex',
  props: ['userData', 'csrfToken'],
  data () {
    return {
      rr: RAILS_ROUTES,
      preparatoryClasses: [],
      preparatoryClassApplications: []
    }
  },
  created () {
    // get the data
    this.axios.all([this.getPreparatoryClasses(), this.getPreparatoryClassApplications(), this.getStatistics()])
      .then((response) => {
        this.preparatoryClasses = response[0]['data']
        this.preparatoryClassApplications = response[1]['data']
        const statistics = response[2]['data']
        statisticsStore.loadData(statistics)
      })
  },
  methods: {
    friendlyDate: function (dateString) {
      return friendlyDate(dateString)
    },
    getTotalPlacements: function () {
      return statisticsStore.getTotalPlacements()
    },
    getTotalConfirmedPlacements: function () {
      return statisticsStore.getTotalConfirmedPlacements()
    },
    getPlacementsCount: function (id) {
      return statisticsStore.getPlacementsCount(id)
    },
    getConfirmedPlacementsCount: function (id) {
      return statisticsStore.getConfirmedPlacementsCount(id)
    },
    getPendingFirstPreferencesCount: function (id) {
      return statisticsStore.getPendingFirstPreferencesCount(id)
    },
    unplacedApplications: function () {
      return Rfilter((item) => {
        return item['placementPreparatoryClassId'] === null
      }, this.preparatoryClassApplications)
    },
    applicationsByClassId: function (cId) {
      return Rfilter((item) => {
        return item['placementPreparatoryClassId'] === cId
      }, this.preparatoryClassApplications)
    },
    getStatistics: function () {
      return this.axios.get(
        '/class/preparatory_class_statistics.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getPreparatoryClasses: function () {
      return this.axios.get(
        '/class/preparatory_classes.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getPreparatoryClassApplications: function () {
      return this.axios.get(
        '/class/preparatory_class_applications.json',
        apiHeadersBuilder(this.csrfToken)
      )
    }
  }
}
