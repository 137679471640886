import { RAILS_ROUTES } from '../constants/index.js'
import CpsAutocompleteObj from '../autocomplete/autocomplete-obj.vue'
import {
  map as Rmap,
  find as Rfind,
  propEq as RpropEq
} from 'ramda'
import uuidv4 from 'uuid/v4'
import {
  addBrowserId,
  appendYearsOld,
  personAgeToday
} from '../form-helpers/index.js'
import { apiHeadersBuilder } from '../api-helpers/index.js'
import { parseISO } from 'date-fns'

export default {
  name: 'PersonForm',
  components: {
    CpsAutocompleteObj
  },
  props: ['csrfToken', 'personJson'],
  data () {
    return {
      rr: RAILS_ROUTES,
      successMessages: [],
      errorMessages: [],
      person: {
        congregation: {
          'value': 0,
          'label': ''
        },
        state: {
          'value': 0,
          'label': ''
        },
        birthState: {
          'value': 0,
          'label': ''
        }
      },
      similarPeople: [],
      qualificationsArray: [
        {value: 'normal', text: 'Normal'},
        {value: 'cna', text: 'CNA'},
        {value: 'lpn', text: 'LPN'},
        {value: 'rn', text: 'RN'},
        {value: 'construction_experience', text: 'Construction Experience'}
      ],
      congregationsList: [
        { 'value': 1, 'label': 'Data is being loaded...'}
      ],
      statesList: [
        {value: 1, label: 'Data is being loaded...'}
      ],
      countriesList: [
        {value: 1, label: 'Data is being loaded...'}
      ]
    }
  },
  created () {
    // call the functions to load the necessary data for selects
    this.axios.all([this.getCongregations(), this.getStates(), this.getCountries()])
      .then((response) => {
        // parse the data
        const cList = response[0]['data']
        const sList = response[1]['data']
        const cntryList = response[2]['data']

        // assign in
        this.congregationsList = cList
        this.statesList = sList
        this.countriesList = cntryList

        let bPerson = JSON.parse(this.personJson)

        // add the id keys for the browser
        // this is so that the text box can be focused after inserting a new item
        bPerson['phoneNumbers'] = addBrowserId(bPerson['phoneNumbers'])
        bPerson['emailAddresses'] = addBrowserId(bPerson['emailAddresses'])
        bPerson['citizenships'] = addBrowserId(bPerson['citizenships'])

        this.person = bPerson

        const personCongregation = Rfind(RpropEq('value', bPerson.congregationId), this.congregationsList)
        const personState = Rfind(RpropEq('value', bPerson.stateId), this.statesList)
        const personBirthState = Rfind(RpropEq('value', bPerson.birthStateId), this.statesList)

        if (personCongregation) {
          this.$refs.congregationInput.setResultParent(personCongregation)
          this.person.congregation = personCongregation
        } else {
          this.person.congregation = {
            value: 0,
            label: ''
          }
        }

        if (personState) {
          this.$refs.stateInput.setResultParent(personState)
          this.person.state = personState
        } else {
          this.person.state = {
            value: 0,
            label: ''
          }
        }

        if (personBirthState) {
          this.$refs.birthStateInput.setResultParent(personBirthState)
          this.person.birthState = personBirthState
        } else {
          this.person.birthState = {
            value: 0,
            label: ''
          }
        }

        // and the citizenship
        // Loop through the citizenship and add the selected country to the countrySelected objec
        for (const ctz of this.person['citizenships']) {
          const ctzCountry = Rfind(RpropEq('value', ctz['countryId']), cntryList)
          ctz['country'] = ctzCountry
        }
      })

  },
  computed: {
    personAge: function () {
      if (this.person['birthDate']) {
        const birthDate = parseISO(this.person['birthDate'])
        return appendYearsOld(personAgeToday(birthDate))
      } else {
        return ''
      }
    }
  },
  methods: {
    showSimilarPeople: function () {
      // only run this if a new person is being entered
      if (this.person['id'] === null) {
        const firstName = this.person['firstName']
        const birthDate = this.person['birthDate']
        const gender = this.person['gender']
        if (!(firstName === null || firstName === "" || birthDate === null || birthDate === "" || gender === null || gender === "")) {
          // look them up!
          this.axios.get(
            '/people/similar_people.json?first_name=' + firstName + '&birth_date=' + birthDate + '&gender=' + gender,
            apiHeadersBuilder(this.csrfToken)
          )
            .then((response) => {
              this.similarPeople = response['data']
            })
        }
      }
    },
    updateState: function (evt) {
      this.person.state = evt
      this.person.stateId = evt.value
    },
    updateBirthState: function (evt) {
      this.person.birthState = evt
      this.person.birthStateId = evt.value
    },
    updateCongregation: function (evt) {
      this.person.congregation = evt
      this.person.congregationId = evt.value
      // look up the city and state for the congregation
        if (this.person['id'] === null) {
          // new person look up some pertinent data
          this.axios.get(
            `/congregations/${this.person['congregationId']}.json`,
            apiHeadersBuilder(this.csrfToken)
          )
            .then((response) => {
              const congregation = response['data']
              if (this.person['city'] === null) {
                this.person['city'] = congregation['city']
              }

              if (this.person['birthCity'] === null) {
                this.person['birthCity'] = congregation['city']
              }


              if (this.person['stateId'] === null) {
                const personState = Rfind(RpropEq('value', congregation['state_id']), this.statesList)
                this.person['stateId'] = congregation['state_id']
                if (personState) {
                  this.$refs.stateInput.setResultParent(personState)
                  this.person.state = personState
                }
              }

              if (this.person['birthStateId'] === null) {
                const personBirthState = Rfind(RpropEq('value', congregation['state_id']), this.statesList)
                this.person['birthStateId'] = congregation['state_id']
                if (personBirthState) {
                  this.$refs.birthStateInput.setResultParent(personBirthState)
                  this.person.birthState = personBirthState
                }
              }
            })
        }
    },
    updateCitizenshipCountry: function (evt, ctz) {
      ctz['country'] = evt
      ctz['countryId'] = evt.value
    },
    getCongregations: function () {
      return this.axios.get(
        '/congregations_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getStates: function () {
      return this.axios.get(
        '/states_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getCountries: function () {
      return this.axios.get(
        '/countries_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    lookupCityZip: function (val) {
      if (this.person['city'] !== null) {
        if (this.person['zip'] === null) {
          this.axios.get(
            `/people/zip_for_city.json?city=${this.person['city']}`,
            apiHeadersBuilder(this.csrfToken)
          )
            .then((response) => {
              const zipData = response['data']
              this.person['zip'] = zipData['zip']
            })
        }
      }
    },
    onSubmit: function (event) {
      event.preventDefault()
      let apiResponse

      if (this.person['id'] === null) {
        // it's a new person
        // post the data
        apiResponse = this.axios.post(
          '/people',
          this.apiParamsBuilder(),
          apiHeadersBuilder(this.csrfToken)
        )
      } else {
        // the form is in edit mode
        apiResponse = this.axios.put(
          '/people/' + this.person['id'],
          this.apiParamsBuilder(),
          apiHeadersBuilder(this.csrfToken)
        )
      }

      apiResponse
        .then((response) => {
          window.location.pathname = `/people/${response['data']['id']}`
        })
        .catch((error) => {
          this.errorMessages = error['response']['data']['errors']
          window.scrollTo(0,0)
        })

    },
    apiParamsBuilder: function () {
      return {
        'utf8': '✓',
        'authenticity_token': this.csrfToken,
        'person': {
          'first_name': this.person['firstName'],
          'middle_name': this.person['middleName'],
          'last_name': this.person['lastName'],
          'birth_date': this.person['birthDate'],
          'gender': this.person['gender'],
          'marital_status': this.person['maritalStatus'],
          'qualification': this.person['qualification'],
          'parents': this.person['parents'],
          'drivers_license': this.person['driversLicense'],
          'congregation_id': this.person['congregationId'],
          'address': this.person['address'],
          'address2': this.person['address2'],
          'city': this.person['city'],
          'state_id': this.person['stateId'],
          'zip': this.person['zip'],
          'birth_city': this.person['birthCity'],
          'birth_state_id': this.person['birthStateId'],
          'phone_numbers_attributes': this.person['phoneNumbers'],
          'email_addresses_attributes': this.person['emailAddresses'],
          'citizenships_attributes': Rmap((item) => {
            return {
              'id': item['id'],
              'country_id': item['countryId'],
              '_destroy': item['_destroy']
            }
          }, this.person['citizenships'])
        },
        'commit': 'Create the person'
      }
    },
    addPhoneNumber: function () {
      const bId = `cps-${uuidv4()}`
      this.person['phoneNumbers'].push(
        {
          browserId: bId,
          id: null,
          number: null,
          description: null,
          _destroy: false
        }
      )
      this.$nextTick(() => {
        document.getElementById(bId).focus()
      })
    },
    addEmailAddress: function () {
      const bId = `cps-${uuidv4()}`
      this.person['emailAddresses'].push(
        {
          browserId: bId,
          id: null,
          address: null,
          _destroy: null
        }
      )
      this.$nextTick(() => {
        document.getElementById(bId).focus()
      })
    },
    addCitizenship: function () {
      const bId = `cps-${uuidv4()}`
      this.person['citizenships'].push(
        {
          browserId: bId,
          id: null,
          countryId: null,
          country: {
            value: 0,
            label: ''
          },
          _destroy: null
        }
      )

      this.$nextTick(() => {
        const refName = `citizenshipInput-${bId}`
        // the refs are an array, even when they are unique, inside of a v-for
        this.$refs[refName][0].setFocusParent()
      })
    },
    deleteNestedItem: function (nItem) {
      // set destroy to true
      nItem['_destroy'] = true
    },
    undeleteNestedItem: function (nItem) {
      // set destroy to true
      nItem['_destroy'] = false
    }
  }
}
