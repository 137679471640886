import { RAILS_ROUTES } from '../constants/index.js'

export default {
  name: 'TheNavbar',
  props: [
    'userId',
    'loggedIn',
    'appRoleResponsibility'
  ],
  data () {
    return {
      rr: RAILS_ROUTES
    }
  },
  computed: {
    isCoordinator: function () {
      if (this.appRoleResponsibility === 'coordinator') {
        return true
      } else {
        return false
      }
    }
  }
}
