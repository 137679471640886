/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'app_vue' %> (and
// <%= stylesheet_pack_tag 'app_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.esm'
import BootstrapVue from 'bootstrap-vue'
import Rails from '@rails/ujs'

// axios
import axios from 'axios'

import App from '../app.vue'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowsAltV,
  faBan,
  faChalkboard,
  faCheckCircle,
  faComment,
  faEnvelope,
  faExclamationTriangle,
  faHandsHelping,
  faPhone,
  faTrashAlt,
  faUndo,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import DateRangeFilter from '../date-range-filter/date-range-filter.vue'
import InPageSearch from '../in-page-search/in-page-search.vue'
import MenuArrivingUnitVolunteers from '../menu-arriving-unit-volunteers/menu-arriving-unit-volunteers.vue'
import MenuCurrentPersonnelReport from '../menu-current-personnel-report/menu-current-personnel-report.vue'
import MenuUap from '../menu-uap/menu-uap.vue'
import MenuUapShow from '../menu-uap-show/menu-uap-show.vue'
import MenuUnitApplications from '../menu-unit-applications/menu-unit-applications.vue'
import MenuUnitShow from '../menu-unit-show/menu-unit-show.vue'
import PersonForm from '../person-form/person-form.vue'
import PinUnitApplication from '../pin-unit-application/pin-unit-application.vue'
import PinUnitApplicationPlacement from '../pin-unit-application-placement/pin-unit-application-placement.vue'
import PreparatoryClassesIndex from '../preparatory-classes/preparatory-classes-index.vue'
import TheCoordinatorNavbar from '../the-coordinator-navbar/the-coordinator-navbar.vue'
import TheNavbar from '../the-navbar/the-navbar.vue'
import UaNotes from '../ua-notes/ua-notes.vue'
import UapButtonsFilter from '../uap-buttons-filter/uap-buttons-filter.vue'
import UnitApplicationCancelationList from '../unit-application-cancelation-list/unit-application-cancelation-list.vue'
import UnitApplicationForm from '../unit-application-form/unit-application-form.vue'
import UnitApplicationPlacementForm from '../unit-application-placement-form/unit-application-placement-form.vue'
import UnitApplicationPlacementWarnings from '../unit-application-placement-warnings/unit-application-placement-warnings.vue'
import UnitApplicationPreferences from '../unit-application-preferences/unit-application-preferences.vue'
import UnitCalendar from '../unit-calendar/unit-calendar.vue'
import UnitNameSearch from '../unit-name-search/unit-name-search.vue'

// store
import store from '../store/index.js'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './stylesheets/application.scss'

Rails.start()

// font awesome
library.add(
  faArrowsAltV,
  faBan,
  faChalkboard,
  faCheckCircle,
  faComment,
  faEnvelope,
  faExclamationTriangle,
  faHandsHelping,
  faPhone,
  faTrashAlt,
  faUndo,
  faUserCheck,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.axios = axios

Vue.use(BootstrapVue)

export const globalBus = new Vue()

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#cps-app',
    store,
    components: {
      App,
      DateRangeFilter,
      InPageSearch,
      MenuArrivingUnitVolunteers,
      MenuCurrentPersonnelReport,
      MenuUap,
      MenuUapShow,
      MenuUnitApplications,
      MenuUnitShow,
      PersonForm,
      PinUnitApplication,
      PinUnitApplicationPlacement,
      PreparatoryClassesIndex,
      TheCoordinatorNavbar,
      TheNavbar,
      UaNotes,
      UapButtonsFilter,
      UnitApplicationCancelationList,
      UnitApplicationForm,
      UnitApplicationPlacementForm,
      UnitApplicationPlacementWarnings,
      UnitApplicationPreferences,
      UnitCalendar,
      UnitNameSearch
    }
  })
})


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'app_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the using turbolinks, install 'vue-turbolinks':
//
// yarn add 'vue-turbolinks'
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
