import { map as Rmap, find as Rfind } from 'ramda'
import uuidv4 from 'uuid/v4'
import { format, parseISO, differenceInYears, startOfToday } from 'date-fns'

export const personAge = (asOfDate, birthDate) => {
  return differenceInYears(asOfDate, birthDate)
}

export const personAgeToday = (birthDate) => {
  const td = startOfToday()
  return personAge(td, birthDate)
}

export const appendYearsOld = (age) => {
  return `${age} years old`
}

export const addBrowserId = (items) => {
  return Rmap((item) => {
    const bId = `cps-${uuidv4()}`
    return {...item, 'browserId': bId}
  }, items)
}

export const vSelectIdFinder = (id, items) => {
  return Rfind((item) => {
    return item['value'] === id
  }, items)
}

export const friendlyDate = (dateString) => {
  const formatPattern = 'MMMM d, yyyy'
  return format(parseISO(dateString), formatPattern)
}

export const humanizePreparatoryClassApplicationStatus = (status) => {
  const statuses = {
    'pending': 'Pending',
    'placed': 'Placed',
    'placement_confirmed': 'Confirmed',
    'placement_refused': 'Refused placement'
  }

  return statuses[status]
}

export const animateOnStateChange = (el) => {
  // this expects to receive a container element
  // the element should have a class of programmatically-can-change
  // the css class has a transition
  // this code changes the background by adding another class, then removes the class
  // this if for giving the user an indication that the state was programmatically changed
  el.classList.remove('programmatically-changed')

  el.classList.add('programmatically-changed')
  setTimeout(() => {
    el.classList.remove('programmatically-changed')
  }, 1500)
}
