import { tableFilterRowsByRowValues, tableFilterRowsRemoveFilter } from '../utilities/index.js'

export default {
  name: 'DateRangeFilter',
  data () {
    return {
      fromDate: '',
      toDate: ''
    }
  },
  methods: {
    clearDateFilter: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')

      tableFilterRowsRemoveFilter({}, tables)

    },
    filterByDate: function () {

      const tables = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        startValue: this.fromDate,
        endValue: this.toDate,
        startValuePosition: 8,
        endValuePosition: 8
      }

      tableFilterRowsByRowValues(options, tables)

    }
  }
}
