import { RAILS_ROUTES } from '../constants/index.js'
import { tbodySortRows, tableFilterRows } from '../utilities/index.js'

export default {
  name: 'MenuUnitApplications',
  props: ['isAdmin'],
  data () {
    return {
      rr: RAILS_ROUTES
    }
  },
  methods: {
    showBoys: function () {
      this.toggleRows(['show-boys'], ['show-girls', 'show-other'])
    },
    showGirls: function () {
      this.toggleRows(['show-girls'], ['show-boys', 'show-other'])
    },
    showOther: function () {
      this.toggleRows(['show-other'], ['show-boys', 'show-girls'])
    },
    showAll: function () {
      this.toggleRows([], ['show-boys', 'show-girls', 'show-other'])
    },
    sortByName: function () {
      const tbody = document.querySelectorAll('tbody.table-with-numbering')
      tbodySortRows({'position': 0}, tbody)
    },
    sortByApplicationDate: function () {
      const tbody = document.querySelectorAll('tbody.table-with-numbering')
      tbodySortRows({'position': 8}, tbody)
    },
    sortByLastNoteDate: function () {
      const tbody = document.querySelectorAll('tbody.table-with-numbering')
      tbodySortRows({'position': 7, 'order': 'DESC'}, tbody)
    },
    toggleRows: function (classToAdd, classToRemove) {
      const tablesToFilter = document.querySelectorAll('table.cps-rows-can-filter')

      const options = {
        classesToAdd: classToAdd,
        classesToRemove: classToRemove
      }

      tableFilterRows(options, tablesToFilter)
    }
  }
}
