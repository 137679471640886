import uuidv4 from 'uuid/v4'

const emptyResult = {
  label: '',
  value: 0
}

export default {
  name: 'CpsAutocompleteObj',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    acResult: {
      type: Object,
      required: false,
      default: () => {},
    }
  },
  data () {
    return {
      currentResult: emptyResult,
      arrowCounter: -1,
      browserId: '',
      isOpen: false,
      results: [],
      acInput: '',
    }
  },
  created () {
    this.acInput = this.acResult.label
    this.browserId = `cps-${uuidv4()}`
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    onArrowDown () {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    toggleResults (evt) {
      evt.preventDefault()
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        // assume if the user opened the list they want to see
        // all of the values
        this.results = this.items
      }
    },
    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter (evt) {
      evt.preventDefault()
      let result
      if (this.arrowCounter === -1) {
        if (this.results.length > 0) {
          result = this.results[0]
        } else {
          result = emptyResult
        }
      } else {
        result = this.results[this.arrowCounter]
      }
      this.setResult(result)
      this.open = false
      this.arrowCounter = -1
    },
    onChange () {
      this.isOpen = true
      this.filterResults()
    },
    onTab () {
      // reset it to the current item
      this.acInput = this.currentResult.label
      this.isOpen = false
    },
    filterResults () {
      this.$nextTick(() => {
        this.results = this.items.filter(item => item.label.toLowerCase().indexOf(this.acInput.toLowerCase()) > -1)
      })
    },
    setResultParent (result) {
      this.currentResult = result
      this.acInput = result.label
    },
    setFocusParent () {
      // if next tick is necessary, the parent is supposed to take care of that
      document.getElementById(this.browserId).focus()
    },
    setResult (result) {
      this.currentResult = result
      this.acInput = result.label
      this.$emit('update:acResult', this.currentResult)
      this.isOpen = false
      // move the focus to the input element
      const el = document.getElementById(this.browserId).focus()
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    }
  }
}
