import { RAILS_ROUTES } from '../constants/index.js'
import { tableFilterRows } from '../utilities/index.js'

export default {
  name: 'UapButtonsFilter',
  data () {
    return {
      rr: RAILS_ROUTES
    }
  },
  methods: {
    showBoys: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')
      const options = {
        classesToAdd: ['show-boys'],
        classesToRemove: ['show-girls', 'show-other']
      }
      tableFilterRows(options, tables)
    },
    showGirls: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')
      const options = {
        classesToAdd: ['show-girls'],
        classesToRemove: ['show-boys', 'show-other']
      }

      tableFilterRows(options, tables)
    },
    showOther: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')
      const options = {
        classesToAdd: ['show-other'],
        classesToRemove: ['show-boys', 'show-girls']
      }
      tableFilterRows(options, tables)
    },
    showAll: function () {
      const tables = document.querySelectorAll('table.cps-rows-can-filter')
      const options = {
        classesToAdd: [],
        classesToRemove: ['show-boys', 'show-girls', 'show-other']
      }

      tableFilterRows(options, tables)
    }
  }
}
