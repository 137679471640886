import { RAILS_ROUTES } from '../constants/index.js'

export default {
  name: 'MenuArrivingUnitVolunteers',
  props: ['reportDateRangeStart', 'reportDateRangeEnd', 'unitIds', 'unitsList'],
  data () {
    return {
      rr: RAILS_ROUTES,
      selectedUnitIds: this.unitIds
    }
  }
}
