export default {
  name: 'PinUnitApplication',
  props: [
    'csrfToken',
    'unitApplicationId'
  ],
  data () {
    return {}
  },
  computed: {
    isApplicationPinned () {
      const item = this.$store.getters.pinnedUnitApplication
      if (item['unitApplicationId'] == this.unitApplicationId) {
        return true
      } else {
        return false
      }
    },
    buttonText () {
      if (this.isApplicationPinned) {
        return "Unpin application"
      } else {
        return "Pin application"
      }
    }
  },
  created () {
    // need to check if the application is pinned
    const params = {
      csrfToken: this.csrfToken,
      unitApplicationId: this.unitApplicationId
    }

    this.$store.dispatch('getPinnedUnitApplication', params)
  },
  methods: {
    toggleApplication: function () {
      const params = {
        csrfToken: this.csrfToken,
        unitApplicationId: this.unitApplicationId
      }

      if (this.isApplicationPinned) {
        this.$store.dispatch('unpinUnitApplication', params)
      } else {
        this.$store.dispatch('pinUnitApplication', params)
      }
    }
  }
}
