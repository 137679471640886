import api from '../../../api/index.js'
import { trace } from '../../../dev-utilities/index.js'
import {
  reduce as Rreduce,
  map as Rmap,
  pipe as Rpipe
} from 'ramda'
import { parseISO, format, formatDistanceToNow, differenceInDays } from 'date-fns'

const state = {
  unitApplicationPlacements: [],
  // these are the unit applications that are not completely replaced
  unreplacedUnitApplicationPlacements: [],
  // these are used to be able to rapidly look up the person that replaces them
  unitApplicationPlacementsIndexedByReplacesId: {}
}

const addMetaFields = (placements) => {
  // this function adds some meta data to the placements
  // pulled in from the api
  // these could be calculated on the fly,
  // but it seems easier to do them now at one go
  const calculationDate = new Date()

  const functionsList = Rpipe(
    addMetaEndDate,
    addMetaStartDate,
    addMetaFormattedEndDate,
    addMetaFormattedStartDate,
    addMetaPlacementEndDateDistanceInWordsToNow,
    addMetaPlacementEndDateDifferenceToNow(calculationDate),
  )

  return Rmap((item) => {
    return functionsList(item)
  }, placements)
}

const addMetaPlacementEndDateDifferenceToNow = (calculationDate) => (p) => {
  return {
    ...p,
    metaPlacementEndDateDifference: differenceInDays(p['metaPlacementEndDate'], calculationDate)
  }
}

const addMetaPlacementEndDateDistanceInWordsToNow = (p) => {
  return {
    ...p,
    metaPlacementEndDateDistanceInWords: formatDistanceToNow(p['metaPlacementEndDate'], { addSuffix: true })
  }
}

const addMetaEndDate = (p) => {
  return {
    ...p,
    metaPlacementEndDate: parseISO(p['placementEndDate'])
  }
}

const addMetaStartDate = (p) => {
  return {
    ...p,
    metaPlacementStartDate: parseISO(p['placementStartDate'])
  }
}

const addMetaFormattedEndDate = (p) => {
  return {
    ...p,
    metaFormattedEndDate: formatDate(p['placementEndDate'])
  }
}

const addMetaFormattedStartDate = (p) => {
  return {
    ...p,
    metaFormattedStartDate: formatDate(p['placementStartDate'])
  }
}

const formatDate = (isoDate) => {
  return format(parseISO(isoDate), 'MMM d, yyyy')
}

const indexPlacementsByReplacesId = (placements) => {
  return Rreduce((acc, value) => {
    acc[value['replacesId']] = value
    return acc
  }, {}, placements)
}

const filterPlacementsByReplacesId = (placements) => {
  return Rreduce((acc, value) => {
    if (value['replacesId'] > 0) {
      return acc.concat([value])
    } else {
      return acc
    }
  }, [], placements)
}

const filterUnreplacedPlacements = (placements, indexedByReplacesId) => {
  return Rreduce((acc, item) => {
    // if the key is found, they are replaced, we just want the ones that are not replaced
    if (indexedByReplacesId.hasOwnProperty(item['unitApplicationPlacementId'])) {
      if (!(indexedByReplacesId[item['unitApplicationPlacementId']]['status'] === 'confirmed')) {
        // this item is needed, it is replaced, but the replacement is not confirmed
        return acc.concat([item])
      } else {
        return acc
      }
    } else {
      return acc.concat([item])
    }
  }, [], placements)

}

const mutations = {
  LOAD_UNIT_APPLICATION_PLACEMENTS (state, payload) {
    state.unitApplicationPlacements = payload
  },
  LOAD_UNREPLACED_UNIT_APPLICATION_PLACEMENTS (state, payload) {
    state.unreplacedUnitApplicationPlacements = payload
  },
  LOAD_UNIT_APPLICATION_PLACEMENTS_INDEXED_BY_REPLACES_ID (state, payload) {
    state.unitApplicationPlacementsIndexedByReplacesId = payload
  }
}

const actions = {
  needPlacements({ commit }, csrfToken) {
    return api.getPlacements(csrfToken)
      .then(response => {
        const payload = addMetaFields(response['data'])
        const placementsThatReplaceSomeone = filterPlacementsByReplacesId(payload)
        const indexedByReplacesId = indexPlacementsByReplacesId(placementsThatReplaceSomeone)
        const unreplacedPlacements = filterUnreplacedPlacements(payload, indexedByReplacesId)
        commit("LOAD_UNIT_APPLICATION_PLACEMENTS_INDEXED_BY_REPLACES_ID", indexedByReplacesId);
        commit("LOAD_UNIT_APPLICATION_PLACEMENTS", payload);
        commit("LOAD_UNREPLACED_UNIT_APPLICATION_PLACEMENTS", unreplacedPlacements);
        return Promise.resolve(payload)
    })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {
  unitApplicationPlacements: state => {
    return state.unitApplicationPlacements
  },
  unreplacedUnitApplicationPlacements: state => {
    return state.unreplacedUnitApplicationPlacements
  },
  placementIsReplaced: state => placement => {
    // 2019-07-10
    // this logic is also duplicated in the rails model for UnitPlacement
    if (state.unitApplicationPlacementsIndexedByReplacesId.hasOwnProperty(placement['unitApplicationPlacementId'])) {
      return true
    } else {
      return false
    }
  },
  replacementForPlacement: state => placement => {
    return state.unitApplicationPlacementsIndexedByReplacesId[placement['unitApplicationPlacementId']]
  }
}

const unitApplicationPlacements = {
  state,
  mutations,
  actions,
  getters
}

export default unitApplicationPlacements
