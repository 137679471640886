import { RAILS_ROUTES } from '../constants/index.js'
import { apiHeadersBuilder } from '../api-helpers/index.js'
import UnitApplicationRemoveFromCancelationList from '../unit-application-cancelation-list/remove-from-cancelation-list.vue'
import { globalBus } from '../packs/app_vue_compiler.js'

export default {
  name: 'UnitApplicationCancelationList',
  components: {
    UnitApplicationRemoveFromCancelationList
  },
  props: [
    'csrfToken',
    'unitId'
  ],
  data () {
    return {
      rr: RAILS_ROUTES,
      unitApplications: [],
      cancelationListVisible: false
    }
  },
  created () {
    this.refreshData()
    globalBus.$on('cancelationListChanged', (data) => {
      this.refreshData()
    })
  },
  methods: {
    refreshData: function () {
      // get the data
      this.axios.all([this.getCancelationList()])
        .then((response) => {
          const spots = response[0]['data']
          this.unitApplications = spots
          if (spots.length > 0) {
            this.cancelationListVisible = true
          } else {
            this.cancelationListVisible = false
          }
        })
    },
    getCancelationList: function () {
      return this.axios.get(
        '/unit_application_cancelation_spots.json?unit_id=' + this.unitId,
        apiHeadersBuilder(this.csrfToken)
      )
    }
  }
}
