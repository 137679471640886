import { searchSearchable } from './../utilities'

export default {
  name: 'InPageSearch',
  props: ['cnameToSearch'],
  data () {
    return {
      'searchText': '',
      'searchableElements': null
    }
  },
  mounted () {
    this.searchableElements = document.getElementsByClassName(this.cnameToSearch)
  },
  methods: {
    doSearch: function (event) {
      const se = this.searchableElements
      const filter = this.searchText

      searchSearchable(filter, se)

    }
  }
}
