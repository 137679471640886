import { RAILS_ROUTES } from '../constants/index.js'
import { apiHeadersBuilder } from '../api-helpers/index.js'

export default {
  name: 'TheNavbar',
  props: [
    'userId',
    'loggedIn',
    'currentPreparatoryClassSeasonDescription',
    'csrfToken'
  ],
  data () {
    return {
      rr: RAILS_ROUTES
    }
  },
  methods: {
    deleteSession: function () {
      this.axios.delete(
        '/logout',
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          // redirect to the login page
          window.location.pathname = '/login'
        })
    }
  }
}
