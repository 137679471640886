import CpsAutocompleteObj from '../autocomplete/autocomplete-obj.vue'
import {
  map as Rmap,
  filter as Rfilter,
  find as Rfind,
  propEq as RpropEq
} from 'ramda'
import uuidv4 from 'uuid/v4'
import { format, parseISO, addYears } from 'date-fns'

import {
  addBrowserId,
  appendYearsOld,
  personAge,
  personAgeToday
} from '../form-helpers/index.js'
import { apiHeadersBuilder } from '../api-helpers/index.js'

export default {
  name: 'UnitApplicationForm',
  components: {
    CpsAutocompleteObj
  },
  props: ['csrfToken', 'unitApplicationJson'],
  data () {
    return {
      successMessages: [],
      errorMessages: [],
      unitApplication: {
        personId: null,
        person: {
          label: '',
          value: 0
        },
        spouseId: null,
        spouse: {
          label: '',
          value: 0
        }
      },
      unitApplicationPreferencesSelected: [],
      personExtraInfo: {},
      spouseExtraInfo: {},
      peopleList: [
        { 'value': 1, 'label': 'Data is being loaded...' }
      ],
      unitsList: []
    }
  },
  created () {
    // get the url, the params are important
    const url = new URL(window.location.href)
    const urlPersonId = url.searchParams.get('person_id')

    // call the functions to load the data for the selects
    this.axios.all([this.getPeople(), this.getUnits()])
      .then((response) => {
        const pList = response[0]['data']
        const uList = response[1]['data']
        this.peopleList = pList
        this.unitsList = uList

        let bUnitApplication = JSON.parse(this.unitApplicationJson)
        // find the person to fill the autocomplete with the right value
        const p = Rfind(RpropEq('value', bUnitApplication.personId), this.peopleList)
        const s = Rfind(RpropEq('value', bUnitApplication.spouseId), this.peopleList)



        bUnitApplication['otherPeople'] = addBrowserId(bUnitApplication['unitApplicationOtherPeople'])
        this.unitApplication = bUnitApplication

        if (s) {
          this.$refs.spouseInput.setResultParent(s)
          this.unitApplication.spouse = s
        }

        this.$refs.personInput.setResultParent(p)
        this.unitApplication.person = p

        // all the unitApplicationPreferencesSelected needs is just the ids
        const unitPreferenceIds = Rmap((item) => {
          return item['unitId']
        }, this.unitApplication['unitApplicationPreferences'])

        this.unitApplicationPreferencesSelected = unitPreferenceIds

        // find the correct option to load the person select
        // there is the chance that this person is passed in as a url parameter
        let personIdToFind = null

        if (this.unitApplication['personId']) {
          personIdToFind = this.unitApplication['personId']
        } else if (urlPersonId) {
          personIdToFind = parseInt(urlPersonId, 10)
          this.unitApplication['personId'] = personIdToFind
        }

        if (this.unitApplication['personId']) {
          this.getPersonData()
        }
      })
  },
  computed: {
    personAge: function () {
      if (this.personExtraInfo['birthDate']) {
        const birthDate = parseISO(this.personExtraInfo['birthDate'])
        return appendYearsOld(personAgeToday(birthDate))
      } else {
        return ''
      }
    },
    spouseAge: function () {
      if (this.spouseExtraInfo['birthDate']) {
        const birthDate = parseISO(this.spouseExtraInfo['birthDate'])
        return appendYearsOld(personAgeToday(birthDate))
      } else {
        return ''
      }
    },
    ageAtAvailableDate: function () {
      if (this.personExtraInfo['birthDate'] && this.unitApplication['availableDate']) {
        const bDate = parseISO(this.personExtraInfo['birthDate'])
        const aDate = parseISO(this.unitApplication['availableDate'])
        return appendYearsOld(personAge(aDate, bDate))
      } else {
        return ''
      }
    },
    unitsVisibleList: function () {
      // if the form is in new mode, just get the active units
      let filteredUnitsList

      if (!this.unitApplication['id']) {
        filteredUnitsList = Rfilter((item) => {
          return item['status'] === 'active'
        }, this.unitsList)
      } else {
        filteredUnitsList = this.unitsList
      }

      if (this.unitApplication['position'] === 'boys_unit_volunteer') {
        return Rfilter((item) => {
          return item['classification'] === 'boys'
        }, filteredUnitsList)
      } else if (this.unitApplication['position'] === 'girls_unit_volunteer') {
        return Rfilter((item) => {
          return item['classification'] === 'girls'
        }, filteredUnitsList)
      } else {
        return filteredUnitsList
      }
    }
  },
  methods: {
    onSubmit: function (event) {
      event.preventDefault()
      let apiResponse

      if (this.unitApplication['id'] === null) {
        // it's not a new application
        // post the data
        apiResponse = this.axios.post(
          '/unit_applications',
          this.apiParamsBuilder(),
          apiHeadersBuilder(this.csrfToken)
        )
      } else {
        // the form is in edit mode
        apiResponse = this.axios.put(
          '/unit_applications/' + this.unitApplication['id'],
          this.apiParamsBuilder(),
          apiHeadersBuilder(this.csrfToken)
        )
      }

      apiResponse
        .then((response) => {
          window.location.pathname = `/unit_applications/${response['data']['id']}`
        })
        .catch((error) => {
          this.errorMessages = error['response']['data']['errors']
          window.scrollTo(0,0)
        })
    },
    updatePerson: function (evt) {
      this.unitApplication.person = evt
      this.unitApplication.personId = evt.value
      this.getPersonData()
    },
    updateSpouse: function (evt) {
      this.unitApplication.spouse = evt
      this.unitApplication.spouseId = evt.value
      this.getSpouseData()
    },
    setUnitApplicationPreferences: function (options) {
      let getIds = Rmap((item) => {
        return item['id']
      })
      let prefFilter
      if (options.hasOwnProperty('workType')) {
        prefFilter = Rfilter((item) => {
          return (item['classification'] === options['gender'] && item['workType'] === options['workType'])
        })
      } else {
        prefFilter = Rfilter((item) => {
          return (item['classification'] === options['gender'])
        })
      }

      const ids = getIds(prefFilter(this.unitsVisibleList))

      this.unitApplicationPreferencesSelected = ids
    },
    setAvailableDate: function () {
      const SUGGESTED_AGE = 19; // age the volunteer should be
      // the logic is to set the available date to their nineteenth birthday
      // if they are older than that, set the available date to the submitted date
      // but only if the available date hasn't already been set
      if (!this.unitApplication['availableDate']) {
        const pBirthDate = parseISO(this.personExtraInfo['birthDate'])
        const uaSubmittedDate = parseISO(this.unitApplication['submittedDate'])
        const pAge = personAge(uaSubmittedDate, pBirthDate)
        if (pAge >= SUGGESTED_AGE) {
          this.unitApplication['availableDate'] = this.unitApplication['submittedDate']
        } else {
          // add SUGGESTED_AGE to their birth date
          const formattedDate = format(addYears(pBirthDate, SUGGESTED_AGE), 'yyyy-MM-dd')
          this.unitApplication['availableDate'] = formattedDate
        }
      }
    },
    setUnitPosition: function () {
      // set the unit position based on the gender of the person
      if (this.personExtraInfo['gender']) {
        if (this.spouseExtraInfo['gender']) {
          // they are both filled, set the position to houseparents
          this.unitApplication['position'] = 'houseparents'
        } else {
          // just have one person, set the position based on gender
          if (this.personExtraInfo['gender'] === 'male') {
            this.unitApplication['position'] = 'boys_unit_volunteer'
          } else {
            this.unitApplication['position'] = 'girls_unit_volunteer'
          }
        }
      }
    },
    addOtherPerson: function () {
      const bId = `cps-${uuidv4()}`
      this.unitApplication['otherPeople'].push(
        {
          browserId: bId,
          id: null,
          name: null,
          birthDate: null,
          _destroy: null
        }
      )
      this.$nextTick(() => {
        document.getElementById(`${bId}-name`).focus()
      })
    },
    deleteNestedItem: function (nItem) {
      // set destroy to true
      nItem['_destroy'] = true
    },
    undeleteNestedItem: function (nItem) {
      // set destroy to true
      nItem['_destroy'] = false
    },
    getPersonData: function () {
      this.axios.get(
        '/people/' + this.unitApplication['personId'] + '.json',
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          const data = response['data']
          this.personExtraInfo = data
          this.setUnitPosition()
        })
    },
    getSpouseData: function () {
      if (this.unitApplication['spouseId']) {
        this.axios.get(
          '/people/' + this.unitApplication['spouseId'] + '.json',
          apiHeadersBuilder(this.csrfToken)
        )
          .then((response) => {
            const data = response['data']
            this.spouseExtraInfo = data
            this.setUnitPosition()
          })
      }
    },
    getPeople: function () {
      return this.axios.get(
        '/people_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    getUnits: function () {
      return this.axios.get(
        '/units_list.json',
        apiHeadersBuilder(this.csrfToken)
      )
    },
    apiParamsBuilder: function () {
      return {
        'utf8': '✓',
        'authenticity_token': this.csrfToken,
        'unit_application': {
          'person_id': this.unitApplication['personId'],
          'spouse_id': this.unitApplication['spouseId'],
          'submitted_date': this.unitApplication['submittedDate'],
          'available_date': this.unitApplication['availableDate'],
          'status': this.unitApplication['status'],
          'position': this.unitApplication['position'],
          'minister_name': this.unitApplication['ministerName'],
          'minister_email_address': this.unitApplication['ministerEmailAddress'],
          'minister_phone_number': this.unitApplication['ministerPhoneNumber'],
          'unit_ids': this.unitApplicationPreferencesSelected,
          'unit_application_other_people_attributes': Rmap((item) => {
            return {
              'id': item['id'],
              'name': item['name'],
              'birth_date': item['birthDate'],
              '_destroy': item['_destroy']
            }
          }, this.unitApplication['otherPeople'])
        }
      }
    }
  }
}
