import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import { apiHeadersBuilder } from '../api-helpers/index.js'
import { RAILS_ROUTES } from '../constants/index.js'
import {
  reduce as Rreduce,
  append as Rappend
} from 'ramda'
import { format, parseISO } from 'date-fns'

require("vue-simple-calendar/static/css/default.css");

export default {
  name: 'UnitCalendar',
  props: [
    'csrfToken',
    'unitId',
  ],
  components: {
    CalendarView,
    CalendarViewHeader
  },
  data () {
    return {
      showDate: new Date(),
      calendarEvents: []
    }
  },
  methods: {
    setShowDate(d) {
      this.showDate = d
    },
    getCurrentUnitApplicationPlacements: function () {
      return this.axios.get(
        '/unit_application_placements.json?unit_id=' + this.unitId + '&current=true',
        apiHeadersBuilder(this.csrfToken)
      )
    }
  },
  created () {
    // get the data
    this.axios.all([this.getCurrentUnitApplicationPlacements()])
      .then((response) => {
        const placements = response[0]['data']
        const calendarPlacements = Rreduce((accum, e) => {

          // get some titles for the events
          const startTitle = `${e.unitApplicationName} arrives ${format(parseISO(e.startDate), 'MMM d, yyyy')}`
          const endTitle = `${e.unitApplicationName} leaves ${format(parseISO(e.endDate), 'MMM d, yyyy')}`

          // each event has to have a unique id
          const sId = `s${e.id}`
          const eId = `e${e.id}`

          const startEvent = {
            id: sId,
            title: startTitle,
            startDate: e.startDate,
            endDate: e.startDate,
            classes: 'arriving'
          }

          const endEvent = {
            id: eId,
            title: endTitle,
            startDate: e.endDate,
            endDate: e.endDate,
            classes: 'leaving'
          }

          return Rappend(endEvent, Rappend(startEvent, accum))
        }, [], placements)

        this.calendarEvents = calendarPlacements
      })
  }
}
