import InPageSearch from './../in-page-search/in-page-search.vue'

export default {
  name: 'UnitNameSearch',
  components: {
    InPageSearch
  },
  data () {
    return {}
  }
}
