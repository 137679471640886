import axios from 'axios';
import { apiHeadersBuilder } from '../api-helpers/index.js';

// let instance = axios.create({
//   baseURL: 'http://localhost:3001/'
// })

const getRecruiterWarnings = (csrfToken) => {
  return new Promise((resolve, reject) => {
    axios.get('/unit_application_placement_warnings.json', apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getPlacements = (csrfToken) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/v1/unit_application_placements.json', apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}


const getPinnedUnitApplications = (csrfToken) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/v1/pinned_unit_applications.json', apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getUnitApplications = (csrfToken) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/v1/unit_applications.json', apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getUnreplacedPlacements = ({ csrfToken, unitId }) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/v1/unreplaced_unit_application_placements/${unitId}.json`, apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getUnitApplication = ({ csrfToken, unitApplicationId }) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/v1/unit_applications/${unitApplicationId}.json`, apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getPinnedUnitApplicationPlacements = (csrfToken) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/v1/pinned_unit_application_placements.json', apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// const createItem = (jsConn, itemDetails) => {
//   const apiUrl = urlBuilder(itemDetails);

//   return new Promise((resolve, reject) => {
//     HTTP.post(apiUrl, jsConn.apiParams, headersBuilder())
//       .then(response => {
//         jsConn.response = response["data"];
//         jsConn.apiCallStatus = "ok";
//         resolve(jsConn);
//       })
//       .catch(e => {
//         jsConn.apiCallStatus = "error";
//         jsConn.error = e;
//         reject(jsConn);
//       });
//   });
// };

const getPinnedUnitApplication = (params) => {
  return new Promise((resolve, reject) => {
    const csrfToken = params['csrfToken']
    const id = params['unitApplicationId']
    axios.get(`/api/v1/pinned_unit_applications/${id}.json`, apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getPinnedUnitApplicationPlacement = (params) => {
  return new Promise((resolve, reject) => {
    const csrfToken = params['csrfToken']
    const id = params['unitApplicationPlacementId']
    axios.get(`/api/v1/pinned_unit_application_placements/${id}.json`, apiHeadersBuilder(csrfToken))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const pinUnitApplication = (params) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/pinned_unit_applications.json', { pinned_unit_application: { unit_application_id: params['unitApplicationId'] } }, apiHeadersBuilder(params['csrfToken']))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const pinUnitApplicationPlacement = (params) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/pinned_unit_application_placements.json', { pinned_unit_application_placement: { unit_application_placement_id: params['unitApplicationPlacementId'] } }, apiHeadersBuilder(params['csrfToken']))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// const unpinUnitApplication = (jsConn, itemDetails) => {
//   const apiUrl = urlBuilder(itemDetails);

//   return new Promise((resolve, reject) => {
//     HTTP.delete(apiUrl + "/" + jsConn.apiParams.id, headersBuilder())
//       .then(response => {
//         jsConn.response = response["data"];
//         jsConn.apiCallStatus = "ok";
//         resolve(jsConn);
//       })
//       .catch(e => {
//         jsConn.apiCallStatus = "error";
//         jsConn.error = e;
//         reject(jsConn);
//       });
//   });
// };

const unpinUnitApplication = (params) => {
  return new Promise((resolve, reject) => {
    const id = params['unitApplicationId']
    axios.delete(`/api/v1/pinned_unit_applications/${id}.json`, apiHeadersBuilder(params['csrfToken']))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const unpinUnitApplicationPlacement = (params) => {
  return new Promise((resolve, reject) => {
    const id = params['unitApplicationPlacementId']
    axios.delete(`/api/v1/pinned_unit_application_placements/${id}.json`, apiHeadersBuilder(params['csrfToken']))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  getPinnedUnitApplication,
  getPinnedUnitApplicationPlacement,
  getPinnedUnitApplicationPlacements,
  getPinnedUnitApplications,
  getPlacements,
  getUnreplacedPlacements,
  getRecruiterWarnings,
  getUnitApplications,
  getUnitApplication,
  pinUnitApplication,
  pinUnitApplicationPlacement,
  unpinUnitApplication,
  unpinUnitApplicationPlacement
}
