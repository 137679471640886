export const searchSearchable = (searchText, searchableElements) => {
  // Dependent on Bootstrap and on the existence of tr elements
  // this function expects to receive some html elements.
  // it searches through them for the searchText and then adjusts
  // the classes for the nearest table row to either hide or show the row
  const regExp = new RegExp(searchText, "i")

  for (let i = 0; i < searchableElements.length; ++i) {
    if (searchableElements[i].textContent.search(regExp) < 0) {
      searchableElements[i].closest('tr').classList.add('d-none') // bootstrap specific
    } else {
      searchableElements[i].closest('tr').classList.remove('d-none') // bootstrap specific
    }
  }
}

export const tableFilterRows = (filterClasses, tablesToFilter) => {
  // This function takes in an object with two keys,
  // an array of classes to add, and one of classes to remove
  // The css is set up in the stylesheet, so adding the class to the table element
  // automatically shows or hides the correct rows.
  // More performant than looping through all the rows and adding or removing classes from them
  const tblClassToAdd = filterClasses['classesToAdd']
  const tblClassToRemove = filterClasses['classesToRemove']
  for (let i = 0; i < tablesToFilter.length; ++i) {
    tablesToFilter[i].classList.add(...tblClassToAdd)
    tablesToFilter[i].classList.remove(...tblClassToRemove)
  }
}

export const tableFilterRowsByRowValues = (options, tablesToFilter) => {
  // This function takes in an object with four keys,
  // The options object needs to have the following:
  // startValue - the value for the start of the range
  // endValue - the value for the end of the range
  // startValuePosition - the td position where the start value will be found
  // endValuePosition  - the td position where the end value will be found
  let startValue = options['startValue']
  let endValue = options['endValue']
  const startValuePosition = options['startValuePosition']
  const endValuePosition = options['endValuePosition']

  // just adjust the start or end value if only one is passed in
  // this way the same loop can be used
  if (endValue === null) {
    endValue = "9999-12-31"
  }

  if (startValue === null) {
    startValue = "0000-00-00"
  }

  for (let i = 0; i < tablesToFilter.length; ++i) {
    const rows = tablesToFilter[i].rows

    for (let i = 0; i < rows.length; ++i) {
      const rowStartValue = rows[i].cells[startValuePosition].textContent
      const rowEndValue = rows[i].cells[endValuePosition].textContent

      if (rowEndValue >= startValue && rowStartValue <= endValue) {
        rows[i].closest('tr').classList.remove('d-none') //bootstrap specific
      } else {
        rows[i].closest('tr').classList.add('d-none') // bootstrap specific
      }

    }
  }
}


export const tableFilterRowsRemoveFilter = (options = {}, tablesToFilter) => {
  // This function takes in some options (current unused) and an array of tables
  // it loops through all the rows in each table and then makes the row visible
  for (let i = 0; i < tablesToFilter.length; ++i) {
    const rows = tablesToFilter[i].rows
    for (let i = 0; i < rows.length; ++i) {
      rows[i].closest('tr').classList.remove('d-none') //bootstrap specific
    }
  }
}

export const tbodySortRows = (options, tbody) => {
  // this function takes two keys in the options
  // the key for position indicates what td cell to sort by
  // the key for order indicates whether the rows should be ascending or descending
  // convert the html collection to an array
  const rows = [].slice.call(tbody[0].rows);
  // sort to the desired order
  const cellPosition = options['position']
  rows.sort((a, b) => {
    const aValue = a.cells[cellPosition].textContent
    const bValue = b.cells[cellPosition].textContent

    if (aValue > bValue) {
      return 1;
    } else if (aValue < bValue) {
      return -1;
    } else {
      return 0;
    }
  })

  if (options.hasOwnProperty('order')) {
    if (options['order'] === 'DESC') {
      rows.reverse()
    }
  }

  let fragment = document.createDocumentFragment();
  rows.forEach((row) => {
    fragment.appendChild(row);
  });
  tbody[0].appendChild(fragment);

}
