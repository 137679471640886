import { RAILS_ROUTES } from '../constants/index.js'

export default {
  name: 'MenuUnitShow',
  props: ['unitId'],
  data () {
    return {
      rr: RAILS_ROUTES
    }
  }
}
