export default {
  name: 'PinUnitApplicationPlacement',
  props: [
    'csrfToken',
    'unitApplicationPlacementId'
  ],
  data () {
    return {}
  },
  computed: {
    isApplicationPlacementPinned () {
      const item = this.$store.getters.pinnedUnitApplicationPlacement
      if (item['unitApplicationPlacementId'] == this.unitApplicationPlacementId) {
        return true
      } else {
        return false
      }
    },
    buttonText () {
      if (this.isApplicationPlacementPinned) {
        return "Unpin application placement"
      } else {
        return "Pin application placement"
      }
    }
  },
  created () {
    // need to check if the application placement is pinned
    const params = {
      csrfToken: this.csrfToken,
      unitApplicationPlacementId: this.unitApplicationPlacementId
    }

    this.$store.dispatch('getPinnedUnitApplicationPlacement', params)
  },
  methods: {
    toggleApplicationPlacement: function () {
      const params = {
        csrfToken: this.csrfToken,
        unitApplicationPlacementId: this.unitApplicationPlacementId
      }

      if (this.isApplicationPlacementPinned) {
        this.$store.dispatch('unpinUnitApplicationPlacement', params)
      } else {
        this.$store.dispatch('pinUnitApplicationPlacement', params)
      }
    }
  }
}
