import { apiHeadersBuilder } from '../api-helpers/index.js'
import { globalBus } from '../packs/app_vue_compiler.js'

export default {
  name: 'RemoveFromCancelationList',
  props: [
    'csrfToken',
    'id',
  ],
  data () {
    return {}
  },
  methods: {
    removeFromList: function () {
      this.axios.delete(
        `/unit_application_cancelation_spots/${this.id}`,
        apiHeadersBuilder(this.csrfToken)
      )
        .then((response) => {
          globalBus.$emit('cancelationListChanged', response['data'])
        })

    }
  }
}
