import { reduce as Rreduce } from 'ramda'

export const store = {
  state: {
    preparatoryClassStatistics: {},
    totalPlacements: 0,
    totalConfirmedPlacements: 0
  },
  loadData (apiData) {
    // loop through the statistics and add each one to the object
    const statisticsObject = Rreduce((acc, item) => {
      const tempItem = {
        [item['id']]: {
          id: item['id'],
          label: item['label'],
          name: item['name'],
          placements: item['placements'],
          confirmedPlacements: item['confirmedPlacements'],
          pendingFirstPreferences: item['pendingFirstPreferences']
        }
      }
      return {...acc, ...tempItem}
    }, {}, apiData)

    this.state.preparatoryClassStatistics = statisticsObject

    const totalPlacements = Rreduce((acc, item) => {
      return acc + item['placements']
    }, 0, apiData)

    const totalConfirmedPlacements = Rreduce((acc, item) => {
      return acc + item['confirmedPlacements']
    }, 0, apiData)


    this.state.totalPlacements = totalPlacements
    this.state.totalConfirmedPlacements = totalConfirmedPlacements
  },
  getPlacementsCount (id) {
    return this.state.preparatoryClassStatistics[id]['placements']
  },
  getConfirmedPlacementsCount (id) {
    return this.state.preparatoryClassStatistics[id]['confirmedPlacements']
  },
  getPendingFirstPreferencesCount (id) {
    return this.state.preparatoryClassStatistics[id]['pendingFirstPreferences']
  },
  getTotalPlacements () {
    return this.state.totalPlacements
  },
  getTotalConfirmedPlacements () {
    return this.state.totalConfirmedPlacements
  }
}
