import Vue from 'vue'
import Vuex from 'vuex'

import unitApplicationPlacements from '../store/modules/unitApplicationPlacements/index.js'
import unitApplications from '../store/modules/unitApplications/index.js'
import pinnedItems from '../store/modules/pinnedItems/index.js'
import recruiterWarnings from '../store/modules/recruiterWarnings/index.js'
import userDataModule from '../store/modules/userData/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    pinnedItems,
    recruiterWarnings,
    unitApplicationPlacements,
    unitApplications,
    userDataModule
  }
})
