import api from '../../../api/index.js'
import { filter as Rfilter } from 'ramda'

const state = {
  unitApplications: [],
  unitApplication: {}
}

const mutations = {
  LOAD_UNIT_APPLICATIONS (state, payload) {
    state.unitApplications = payload
  },
  LOAD_UNIT_APPLICATION (state, payload) {
    state.unitApplication = payload
  }
}

const actions = {
  needUnitApplications({ commit }, csrfToken) {
    return api.getUnitApplications(csrfToken)
      .then(response => {
        const payload = response['data']
        commit("LOAD_UNIT_APPLICATIONS", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  needUnitApplication({ commit }, { csrfToken, unitApplicationId }) {
    return api.getUnitApplication({ csrfToken: csrfToken, unitApplicationId: unitApplicationId })
      .then(response => {
        const payload = response['data']
        commit("LOAD_UNIT_APPLICATION", payload)
        return Promise.resolve(payload)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

const getters = {
  unitApplications: state => {
    return state.unitApplications
  },
  unitApplication: state => {
    return state.unitApplication
  },
  unitApplicationsByStatus: (state) => (status) => {
    return Rfilter((item) => {
      return item.status === status
    }, state.unitApplications);
  },
}

const unitApplications = {
  state,
  mutations,
  actions,
  getters
}

export default unitApplications
